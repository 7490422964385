//import Sortable from "sortablejs";
import Swiper from 'swiper';
import {Navigation, Pagination} from 'swiper/modules';
import 'swiper/css';


// Tabs Slider
const sliders = document.querySelectorAll('.tabs-slider');
if (sliders.length > 0) {
    Swiper.use([Navigation, Pagination]);
    sliders.forEach((slider) => {
        const id = slider.id;
        let id2 = id.replace("x-", "");
        const swiper = new Swiper(`#${id}`, {
            id: id,
            slidesPerView: 'auto',
            spaceBetween: 20,
            watchOverflow: true,
            //observer: true,
            //loop: true,
            navigation: {
                nextEl: `#y-${id2} .swiper-button-next`,
                prevEl: ` #y-${id2} .swiper-button-prev`,
            },
            pagination: {
                el: `#y-${id2} .swiper-pagination`,
                type: 'custom',
                renderCustom: function (swiper, current, total) {
                    return current + ' / ' + total;
                },
            },
            on: {
                update: function () {
                    toggleTabsSliderControls(this, id2);
                },
                init: function () {
                    toggleTabsSliderControls(this, id2);
                },
                resize: function () {
                    toggleTabsSliderControls(this, id2);
                },
            },
        });
    });
}

// addEventListener("tabopen", (event) => {alert('sdsdsd')});

function toggleTabsSliderControls(swiper, id) {
    const tabsSliderControls = document.getElementById(`y-${id}`);
    //alert(id)
    if (tabsSliderControls) {
        if (swiper.slides.length <= 1 || swiper.isEnd) {
            tabsSliderControls.style.display = 'none';

        } else {
            tabsSliderControls.style.display = 'block';
        }
    }
}





